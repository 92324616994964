import logdown, { Logger } from 'logdown';
import moment from 'moment';
import { Maybe } from 'monet';
import { createTransform, Transform } from 'redux-persist';

import { MonitoringSystem } from '../../entities';
import { MonitoringSystemDeletingState, MonitoringSystemEditingState, MonitoringSystemsListingState, MonitoringSystemSavingState } from '../entities';

/**
 * Entities SerDes
 */

const monitoringSystemSerialize = (ds: MonitoringSystem) => {
  return {
    updatedAt: ds.updatedAt.toISOString(),
    id: ds.id,
    name: ds.name,
    site: ds.site,
    type: ds.type,
    code: ds.code,
    frequency: ds.frequency,
    timeUnit: ds.timeUnit,
    active: ds.active,
    quantity: ds.quantity,
    parser: ds.parser,
    lastEventReceivedAt: ds.lastEventReceivedAt ? ds.lastEventReceivedAt.toISOString() : undefined,
    failing: ds.failing,
    LastIncident: ds.lastIncident,
  };
};

const monitoringSystemDeserialize = (dss: any): MonitoringSystem => ({
  updatedAt: moment(dss.updatedAt).toDate(),
  id: dss.id,
  name: dss.name,
  site: dss.site,
  type: dss.type,
  code: dss.code,
  frequency: dss.frequency,
  timeUnit: dss.timeUnit,
  active: dss.active,
  quantity: dss.quantity,
  parser: dss.parser,
  lastEventReceivedAt: moment(dss.lastEventReceivedAt).toDate(),
  failing: dss.failing,
  lastIncident: dss.lastIncident,
});

/**
 * Transformations
 */

export const createMonitoringSystemsListingTransform = (
  logger: Logger = logdown('redux-persist:transform:monitoringSystemsListing'),
): Transform<MonitoringSystemsListingState, any> =>
  createTransform(
    // transform state on its way to being serialized and persisted.
    (subState, key /*, state*/) => {
      const result = {
        ...subState,
        sort: subState.sort.orUndefined(),
        monitoringSystems: subState.monitoringSystems.map((monitoringSystems) => monitoringSystems.map(monitoringSystemSerialize)).orUndefined(),
        exportedMonitoringSystems: subState.monitoringSystems
          .map((monitoringSystems) => monitoringSystems.map(monitoringSystemSerialize))
          .orUndefined(),
        filter: subState.filter
          .map((filter) => ({
            ...filter,
          }))
          .orUndefined(),
        error: subState.error.map((error) => error.message).orUndefined(),
      };
      // logger.log(`serialize alerts=> (${key.toString()}): ${JSON.stringify(result, undefined, 4)}`);
      return result;
    },
    // transform state being rehydrated
    (state, key /*, rawState*/) => {
      // convert mySet back to a Set.
      // logger.log(`deserialize alerts=> (${key.toString()}): ${JSON.stringify(state, undefined, 4)}`);
      return {
        ...state,
        isInProgress: false,
        sort: Maybe.fromUndefined(state.sort),
        monitoringSystems: Maybe.fromUndefined(state.monitoringSystems).map((alerts) => alerts.map(monitoringSystemDeserialize)),
        exportedMonitoringSystems: Maybe.fromUndefined(state.monitoringSystems).map((alerts) => alerts.map(monitoringSystemDeserialize)),
        // filter: Maybe.fromUndefined(state.filter),
        filter: state.filter
          ? Maybe.fromUndefined({
              ...(state.filter as any),
              updatedAt: Maybe.fromUndefined((state.filter as any).updatedAt),
            })
          : Maybe.None(),
        error: Maybe.fromUndefined(state.error).map((state) => new Error(state)),
      };
      // logger.log(`outboundState after transform: ${JSON.stringify(outboundState, undefined, 4)}`);
      // return res;
    },
    // define which reducers this transform gets called for.
    { whitelist: ['monitoringSystemsListing'] },
  );

export const createMonitoringSystemEditingTransform = (
  logger: Logger = logdown('redux-persist:transform:monitoringSystemEditing'),
): Transform<MonitoringSystemEditingState, any> =>
  createTransform(
    // transform state on its way to being serialized and persisted.
    (subState, key, state) => {
      const result = {
        ...subState,
        monitoringSystem: subState.monitoringSystem.map(monitoringSystemSerialize).orUndefined(),
        error: subState.error.map((error) => error.message).orUndefined(),
      };
      logger.log(`serialize: ${JSON.stringify(result, undefined, 4)}`);
      return result;
    },
    // transform state being rehydrated
    (state, key, rawState) => {
      // convert mySet back to a Set.
      logger.log(`deserialize: ${JSON.stringify(state, undefined, 4)}`);
      return {
        ...state,
        monitoringSystem: Maybe.fromUndefined(state.monitoringSystem).map(monitoringSystemDeserialize),
        error: Maybe.fromUndefined(state.error).map((message) => new Error(message)),
      };
      // logger.log(`outboundState after transform: ${JSON.stringify(outboundState, undefined, 4)}`);
      // return res;
    },
    // define which reducers this transform gets called for.
    { whitelist: ['monitoringSystemEditing'] },
  );

export const createMonitoringSystemSavingTransform = (
  logger: Logger = logdown('redux-persist:transform:monitoringSystemSaving'),
): Transform<MonitoringSystemSavingState, any> =>
  createTransform(
    // transform state on its way to being serialized and persisted.
    (subState, key, state) => {
      const result = {
        ...subState,
        error: subState.error.map((error) => error.message).orUndefined(),
      };
      logger.log(`serialize: ${JSON.stringify(result, undefined, 4)}`);
      return result;
    },
    // transform state being rehydrated
    (state, key, rawState) => {
      // convert mySet back to a Set.
      logger.log(`deserialize: ${JSON.stringify(state, undefined, 4)}`);
      return {
        ...state,
        error: Maybe.fromUndefined(state.error).map((message) => new Error(message)),
      };
      // logger.log(`outboundState after transform: ${JSON.stringify(outboundState, undefined, 4)}`);
      // return res;
    },
    // define which reducers this transform gets called for.
    { whitelist: ['monitoringSystemSaving'] },
  );

export const createMonitoringSystemDeletingTransform = (
  logger: Logger = logdown('redux-persist:transform:monitoringSystemDeleting'),
): Transform<MonitoringSystemDeletingState, any> =>
  createTransform(
    (subState, key, state) => {
      const result = {
        ...subState,
        monitoringSystemId: subState.monitoringSystemId.orUndefined(),
        error: subState.error.map((error) => error.message).orUndefined(),
      };
      // logger.log(`serialized (${key.toString()}): ${JSON.stringify(result, undefined, 4)}`);
      return result;
    },
    (state, key, rawState) => {
      // logger.log(`deserialize (${key.toString()}): ${JSON.stringify(state, undefined, 4)}`);
      return {
        ...state,
        monitoringSystemId: Maybe.fromUndefined(state.monitoringSystemId),
        error: Maybe.fromUndefined(state.error).map((message) => new Error(message)),
      };
    },
    { whitelist: ['monitoringSystemDeleting'] },
  );

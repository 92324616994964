import { MonitoringSystem, MonitoringSystemType, Parser, TimeUnit } from '@serverfarm/nocd-commons';
import moment from 'moment';

export interface MonitoringSystemsListFilterApiEntity {
  providers?: string[];
  sites?: string[];
}

export interface MonitoringSystemApiEntity {
  id: string;
  name: string;
  site: string;
  type: MonitoringSystemType;
  code: string;
  parser: Parser;
  frequency: number;
  timeUnit: TimeUnit;
  active: boolean;
  quantity: number;
  lastEventReceivedAt: string;
  updatedAt: string;
  failing: boolean;
  lastIncident: MonitoringSystemIncidentApiEntity;
}

export interface MonitoringSystemIncidentApiEntity {
  alertId: string;
  monitoringSystemId: string;
  status: string;
  acknowledgedBy?: string;
  acknowledgedAt?: Date;
  closedBy?: string;
  closedAt?: Date;
  createdAt: Date;
}

export const monitoringSystemSerialize = (ds: MonitoringSystem): MonitoringSystemApiEntity => {
  return {
    updatedAt: moment(ds.updatedAt).toISOString(),
    id: ds.id,
    name: ds.name,
    site: ds.site,
    type: ds.type,
    code: ds.code,
    active: ds.active,
    frequency: ds.frequency,
    timeUnit: ds.timeUnit,
    parser: ds.parser,
    quantity: ds.quantity,
    lastEventReceivedAt: moment(ds.lastEventReceivedAt).toISOString(),
    failing: ds.failing,
    lastIncident: ds.lastIncident,
  };
};

export const monitoringSystemDeserialize = (dss: MonitoringSystemApiEntity): MonitoringSystem => {
  return {
    updatedAt: moment(dss.updatedAt).toDate(),
    id: dss.id,
    name: dss.name,
    site: dss.site,
    type: dss.type,
    code: dss.code,
    active: dss.active,
    parser: dss.parser,
    frequency: dss.frequency,
    timeUnit: dss.timeUnit,
    quantity: dss.quantity,
    lastEventReceivedAt: dss.lastEventReceivedAt ? moment(dss.lastEventReceivedAt).toDate() : undefined,
    failing: dss.failing,
    lastIncident: dss.lastIncident,
  };
};

export interface MonitoringSystemsListQueryResponse {
  monitoringSystems: MonitoringSystemApiEntity[];
  page: number;
  perPage: number;
  total: number;
}

export interface MonitoringSystemsStatusCheckResponse {
  status: string;
}

import { MonitoringSystemType, TimeUnit } from '@serverfarm/nocd-commons';
import logdown from 'logdown';
import { Maybe } from 'monet';
import React from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Typeahead } from 'react-bootstrap-typeahead';
// @ts-ignore
import EllipsisText from 'react-ellipsis-text';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import {
  Button,
  Form,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  UncontrolledCollapse,
} from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';
import { fetchCompanies } from '../user';

import {
  completeMonitoringSystemEdit,
  editMonitoringSystem,
  editMonitoringSystemSite,
  editMonitoringSystemName,
  editMonitoringSystemCode,
  editMonitoringSystemType,
  editMonitoringSystemFrequency,
  editMonitoringSystemTimeUnit,
  editMonitoringSystemSev1Def,
  editMonitoringSystemSev2Def,
  editMonitoringSystemSev3Def,
  editMonitoringSystemSev4Def,
  editMonitoringSystemSev5Def,
  editMonitoringSystemNormalDef,
  editMonitoringSystemOffnormalDef,
  saveMonitoringSystem,
} from './data/monitoring-systems/actions';

const logger = logdown('components:MonitoringSystemEditor');

class Component extends React.Component<Props, { isCollapsed: boolean; bottomRef: any }> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isCollapsed: false,
      bottomRef: React.createRef(),
    };
  }

  toggleCollapse = () => {
    this.setState((prevState) => ({ isCollapsed: !prevState.isCollapsed }));
  };

  public render() {
    const generateRandomCode = (): string => {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < 8; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
      }
      return result;
    };

    const scrollToBottom = () => {
      console.log(this.state.bottomRef);
      if (this.state.bottomRef.current) {
        this.state.bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    };

    return (
      <Modal
        data-testid="monitoringsystem-editor-modal"
        id="monitoringsystem-editor-modal"
        size="lg"
        isOpen={this.props.monitoringSystemEditing.isInProgress}
        toggle={this.cancel.bind(this)}
      >
        <ModalHeader data-testid="monitoringsystem-editor-header" id="monitoringsystem-editor-header">
          <EllipsisText
            text={`Edit '${this.props.monitoringSystemEditing.monitoringSystem.map((t: any) => t.name || '...').getOrElse('...')}'`}
            length={45}
          />
        </ModalHeader>
        <ModalBody
          style={{
            maxHeight: '600px',
            overflowY: 'scroll',
          }}
        >
          <Form onSubmit={this.submit.bind(this)}>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                data-testid="monitoringsystem-editor-name-input"
                id="name"
                type="text"
                value={this.props.monitoringSystemEditing.monitoringSystem.map((t) => t.name || '').getOrElse('')}
                onChange={(e) => this.props.editMonitoringSystemName(e.target.value)}
              />
              {/* <FormFeedback invalid="true">You will not be able to see this</FormFeedback> */}
              <FormText>Type name of the Monitoring System for future reference</FormText>
            </FormGroup>
            <FormGroup>
              <Label for="report-editor-company">Site</Label>
              {(() => {
                let ref: any;
                return (
                  <Typeahead
                    id="report-editor-company"
                    onBlur={() => ref.hideMenu()}
                    ref={(el) => (ref = el)}
                    isLoading={this.props.sitesListing.isInProgress}
                    onChange={(selected) => this.props.editMonitoringSystemSite(selected[0])}
                    options={this.props.sitesListing.sites.getOrElse([]).map((s) => s.monitorSiteId)}
                    emptyLabel="No Sites found"
                    placeholder="Start typing to find a Site by name"
                    defaultSelected={this.props.monitoringSystemEditing.monitoringSystem
                      .flatMap((t) => Maybe.fromUndefined(t.site))
                      .map((t) => [t])
                      .getOrElse([])}
                    // isValid={this.props.reportEditing.report.flatMap(report => report.company).isSome()}
                    isInvalid={this.props.monitoringSystemEditing.monitoringSystem.flatMap((t) => Maybe.fromUndefined(t.site)).isNone()}
                    // disabled={this.props.monitoringSystemEditing.monitoringSystem.map((t) => !!t.id).contains(true)}
                  />
                );
              })()}
              <FormText>Choose a Site to bind the Monitoring System </FormText>
            </FormGroup>
            <FormGroup>
              <Label for="report-editor-type">Type</Label>
              {(() => {
                let ref: any;
                return (
                  <Typeahead
                    id="report-editor-type"
                    onBlur={() => ref.hideMenu()}
                    ref={(el) => (ref = el)}
                    onChange={(selected) => this.props.editMonitoringSystemType(selected[0])}
                    options={Object.values(MonitoringSystemType)}
                    emptyLabel="No Type found"
                    placeholder="Start typing to find a Type by name"
                    defaultSelected={this.props.monitoringSystemEditing.monitoringSystem
                      .flatMap((t) => Maybe.fromUndefined(t.type))
                      .map((t) => [t])
                      .getOrElse([])}
                    // isValid={this.props.reportEditing.report.flatMap(report => report.company).isSome()}
                    isInvalid={this.props.monitoringSystemEditing.monitoringSystem.flatMap((t) => Maybe.fromUndefined(t.type)).isNone()}
                    // disabled={this.props.monitoringSystemEditing.monitoringSystem.map((t) => !!t.id).contains(true)}
                  />
                );
              })()}
              <FormText>Choose a Site to bind the Monitoring System </FormText>
            </FormGroup>
            <FormGroup>
              <Label for="name">Code</Label>
              <InputGroup>
                <Input
                  data-testid="monitoringsystem-editor-name-input"
                  id="name"
                  type="text"
                  value={this.props.monitoringSystemEditing.monitoringSystem.map((t) => t.code || '').getOrElse('')}
                  onChange={(e) => this.props.editMonitoringSystemCode(e.target.value)}
                  //disabled={this.props.monitoringSystemEditing.monitoringSystem.map((t) => !!t.id).contains(true)}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText
                    className="text-filter"
                    style={{
                      cursor: 'pointer',
                      background: 'white',
                      border: '1px solid #dddddd',
                      borderLeft: '0',
                    }}
                  >
                    <>
                      <Icon.Shuffle
                        id={`code-generator`}
                        style={{ marginRight: '6px' }}
                        onClick={() => {
                          this.props.editMonitoringSystemCode(generateRandomCode());
                        }}
                      />
                    </>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              {/* <FormFeedback invalid="true">You will not be able to see this</FormFeedback> */}
              <FormText>Type the code linked to Monitoring System</FormText>
            </FormGroup>
            <FormGroup>
              <Label for="frequency">Frequency</Label>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input
                  data-testid="monitoringsystem-editor-frequency-input"
                  id="frequency"
                  type="number"
                  min="0"
                  value={this.props.monitoringSystemEditing.monitoringSystem.map((t) => Math.abs(t.frequency) || '').getOrElse('')}
                  onChange={(e) => this.props.editMonitoringSystemFrequency(Math.abs(Number(e.target.value)))}
                  style={{ flex: 1 }}
                />
                <Input
                  type="select"
                  id="timeUnit"
                  value={this.props.monitoringSystemEditing.monitoringSystem.map((t) => t.timeUnit || 'HOURS').getOrElse('HOURS' as TimeUnit)}
                  onChange={(e) => this.props.editMonitoringSystemTimeUnit(e.target.value as TimeUnit)}
                  style={{ flex: 1, marginLeft: '10px' }}
                >
                  <option value="HOURS">HOURS</option>
                  <option value="MINUTES">MINUTES</option>
                </Input>
              </div>
              <FormText>
                Type the amount of time and select the unit (HOURS or MINUTES) that it should take to receive a new event from this Monitoring System
              </FormText>
            </FormGroup>

            <div>
              <div
                id="toggler"
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '10px 15px',
                  borderBottom: '1px solid #ddd',
                  marginBottom: '1rem',
                }}
                onClick={() => {
                  this.toggleCollapse();
                  if (!this.state.isCollapsed) {
                    setTimeout(scrollToBottom, 500);
                  }
                }}
              >
                <span>Parsing Definitions</span>
                <Icon.ChevronDown size={24} style={{ transform: this.state.isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)' }} />
              </div>
              <UncontrolledCollapse toggler="#toggler" isOpen={this.state.isCollapsed}>
                <FormGroup>
                  <Label for="name">Severity Level 1</Label>
                  <Input
                    data-testid="monitoringsystem-editor-sev1-def-input"
                    id="name"
                    type="text"
                    value={this.props.monitoringSystemEditing.monitoringSystem.map((t) => t.parser.sev1_def || '').getOrElse('')}
                    onChange={(e) => this.props.editMonitoringSystemSev1Def(e.target.value)}
                  />
                  {/* <FormFeedback invalid="true">You will not be able to see this</FormFeedback> */}
                </FormGroup>
                <FormGroup>
                  <Label for="name">Severity Level 2</Label>
                  <Input
                    data-testid="monitoringsystem-editor-sev2-input"
                    id="name"
                    type="text"
                    value={this.props.monitoringSystemEditing.monitoringSystem.map((t) => t.parser.sev2_def || '').getOrElse('')}
                    onChange={(e) => this.props.editMonitoringSystemSev2Def(e.target.value)}
                  />
                  {/* <FormFeedback invalid="true">You will not be able to see this</FormFeedback> */}
                </FormGroup>
                <FormGroup>
                  <Label for="name">Severity Level 3</Label>
                  <Input
                    data-testid="monitoringsystem-editor-sev3-input"
                    id="name"
                    type="text"
                    value={this.props.monitoringSystemEditing.monitoringSystem.map((t) => t.parser.sev3_def || '').getOrElse('')}
                    onChange={(e) => this.props.editMonitoringSystemSev3Def(e.target.value)}
                  />
                  {/* <FormFeedback invalid="true">You will not be able to see this</FormFeedback> */}
                </FormGroup>
                <FormGroup>
                  <Label for="name">Severity Level 4</Label>
                  <Input
                    data-testid="monitoringsystem-editor-sev4-input"
                    id="name"
                    type="text"
                    value={this.props.monitoringSystemEditing.monitoringSystem.map((t) => t.parser.sev4_def || '').getOrElse('')}
                    onChange={(e) => this.props.editMonitoringSystemSev4Def(e.target.value)}
                  />
                  {/* <FormFeedback invalid="true">You will not be able to see this</FormFeedback> */}
                </FormGroup>
                <FormGroup>
                  <Label for="name">Severity Level 5</Label>
                  <Input
                    data-testid="monitoringsystem-editor-sev5-input"
                    id="name"
                    type="text"
                    value={this.props.monitoringSystemEditing.monitoringSystem.map((t) => t.parser.sev5_def || '').getOrElse('')}
                    onChange={(e) => this.props.editMonitoringSystemSev5Def(e.target.value)}
                  />
                  {/* <FormFeedback invalid="true">You will not be able to see this</FormFeedback> */}
                </FormGroup>
                <FormGroup>
                  <Label for="name">Normal State</Label>
                  <Input
                    data-testid="monitoringsystem-editor-normal-def-input"
                    id="name"
                    type="text"
                    value={this.props.monitoringSystemEditing.monitoringSystem.map((t) => t.parser.normal_def || '').getOrElse('')}
                    onChange={(e) => this.props.editMonitoringSystemNormalDef(e.target.value)}
                  />
                  {/* <FormFeedback invalid="true">You will not be able to see this</FormFeedback> */}
                </FormGroup>
                <FormGroup>
                  <Label for="name">Offnormal State</Label>
                  <Input
                    data-testid="monitoringsystem-editor-normal-def-input"
                    id="name"
                    type="text"
                    value={this.props.monitoringSystemEditing.monitoringSystem.map((t) => t.parser.offnormal_def || '').getOrElse('')}
                    onChange={(e) => this.props.editMonitoringSystemOffnormalDef(e.target.value)}
                  />
                  {/* <FormFeedback invalid="true">You will not be able to see this</FormFeedback> */}
                </FormGroup>
                <div ref={this.state.bottomRef} />
              </UncontrolledCollapse>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            data-testid="monitoringsystem-editor-save-button"
            id="monitoringsystem-editor-save-button"
            color="primary"
            disabled={!this.props.monitoringSystemEditing.monitoringSystem || this.props.monitoringSystemSaving.isInProgress}
            onClick={this.submit.bind(this)}
          >
            {this.props.monitoringSystemSaving.isInProgress ? <Spinner size="sm" /> : 'Save'}
          </Button>{' '}
          <Button
            data-testid="monitoringsystem-editor-cancel-button"
            id="monitoringsystem-editor-cancel-button"
            color="secondary"
            disabled={this.props.monitoringSystemSaving.isInProgress}
            onClick={this.cancel.bind(this)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  protected submit(e: any) {
    e.preventDefault();
    this.props.monitoringSystemEditing.monitoringSystem.forEach((monitoringSystem) =>
      this.props.saveMonitoringSystem(monitoringSystem, !monitoringSystem.id),
    );
  }

  protected cancel(e: any) {
    e.preventDefault();
    this.props.completeMonitoringSystemEdit();
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      startMonitoringSystemEdit: editMonitoringSystem,
      editMonitoringSystemName,
      editMonitoringSystemSite,
      editMonitoringSystemCode,
      editMonitoringSystemType,
      editMonitoringSystemFrequency,
      editMonitoringSystemTimeUnit,
      editMonitoringSystemSev1Def,
      editMonitoringSystemSev2Def,
      editMonitoringSystemSev3Def,
      editMonitoringSystemSev4Def,
      editMonitoringSystemSev5Def,
      editMonitoringSystemNormalDef,
      editMonitoringSystemOffnormalDef,
      completeMonitoringSystemEdit,
      saveMonitoringSystem,
      fetchCompanies,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  monitoringSystemEditing: state.monitoringSystemEditing,
  monitoringSystemSaving: state.monitoringSystemSaving,
  sitesListing: state.sitesListing,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);
type Props = typeof stateProps & typeof dispatchProps;

// @ts-ignore
export default connect<typeof stateProps, typeof dispatchProps, any>(mapStateToProps, mapDispatchToProps)(Component);

import { SeverityLevel, AlertState, RawNotificationStatus } from '@serverfarm/nocd-commons';

const DANGER = '#dc0000';
const GREEN = '#00ac46';

export const getBadgeColor = (severity: SeverityLevel): string => {
  switch (severity) {
    // case SeverityLevel.UNKNOWN:
    //   return '#ff0505';
    case SeverityLevel.HIGHEST:
      return '#780000';
    case SeverityLevel.HIGH:
      return DANGER;
    case SeverityLevel.MEDIUM:
      return '#fd8c00';
    case SeverityLevel.LOW:
      return '#fdc500';
    case SeverityLevel.LOWEST:
      return GREEN; // '#fff000'
    default:
      return '#bcbcbc';
  }
};

export const getBadgeStatusColor = (status: string): string => {
  switch (status) {
    // case SeverityLevel.UNKNOWN:
    //   return '#ff0505';
    case 'ESCALATED':
      return '#fd8c00';
    case 'ACKNOWLEDGED':
      return '#fdc500';
    case 'CLOSED':
      return GREEN; // '#fff000'
    default:
      return '#bcbcbc';
  }
};

export const getStateColor = (state: AlertState): string => {
  switch (state) {
    case AlertState.NORMAL:
      return GREEN;
    case AlertState.OFF_NORMAL:
      return DANGER;
    case AlertState.ACKNOWLEDGED:
      return GREEN;
    default:
      return DANGER;
  }
};
export const getInMaintenanceColor = (isInMaintenance?: boolean): string => {
  return isInMaintenance ? DANGER : GREEN;
};

export const getAcknowledgedColor = (isAcknowledged: boolean): string => {
  return isAcknowledged ? GREEN : DANGER;
};

export const getStatusColor = (status: RawNotificationStatus): string => {
  switch (status) {
    case RawNotificationStatus.PARSING_FAILED:
      return '#fdc500';
    case RawNotificationStatus.ENRICHING_FAILED:
      return '#fd8c00';
    case RawNotificationStatus.ALERT_NOTIFICATION_PARSER_FAILED:
      return DANGER;
    case RawNotificationStatus.CREATION_SUCCESS:
      return GREEN;
    default:
      return DANGER;
  }
};
